<template>
  <v-card>
    <v-list v-if="name && hasDedicatedSupport">
      <v-list-item>
        <v-list-item-avatar>
          <img
            :src="photo"
            :alt="name"
          >
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-list>
      <v-list-item v-if="company && isExpert && hasDedicatedSupport">
        <v-list-item-icon>
          <v-icon>mdi-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a :href="`tel:${phone}`">
              {{ phone }}
            </a>
          </v-list-item-title>
          <v-list-item-subtitle>
            Telefon
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <template v-else-if="!isBasic && hasDedicatedSupport">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="`tel:${generalPhoneDE}`">
                {{ generalPhoneDE }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>
              Telefon DE
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="`tel:${generalPhoneCH}`">
                {{ generalPhoneCH }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>
              Telefon CH
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item v-if="!isLight">
        <v-list-item-icon>
          <v-icon>mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a :href="`mailto:${email}`">
              {{ email }}
            </a>
          </v-list-item-title>
          <v-list-item-subtitle>
            E-Mail
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="calendlyLink">
        <v-list-item-icon>
          <v-icon>mdi-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a
              :href="calendlyLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termin vereinbaren
            </a>
          </v-list-item-title>
          <v-list-item-subtitle> über Calendly </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="whatsappLink">
        <v-list-item-icon>
          <v-icon>mdi-whatsapp</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a
              :href="whatsappLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nachricht senden
            </a>
          </v-list-item-title>
          <v-list-item-subtitle> Whatsapp Chat </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import featureNames from '@/lib/featureNames'
import {
  isAuthenticatedAndExpertUser,
  isAuthenticatedAndBasicUser,
  isAuthenticatedAndLightUser
} from '@/lib/productTypes'
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  props: {
    company: {
      type: Object,
      default: null
    }
  },

  data () {
    const defaultContact = this.$features.feature(featureNames.SUPPORT_CONTACT).config
    return {
      generalPhoneCH: defaultContact.phone.ch,
      generalPhoneDE: defaultContact.phone.de,
      allowedCompanies: [
        '5e4408b45d877c001934c653', // roadshow-de (staging)
        '5da85468aac9730018836c3f' // max-makler-immobilien-de (production)
      ]
    }
  },

  computed: {
    phone () {
      const { clientAdvisor, country } = this.company

      if (clientAdvisor) {
        return clientAdvisor.phone[country]
      }

      return this.defaultContact.phone[country]
    },

    email () {
      if (isAuthenticatedAndBasicUser(this.$auth)) {
        return this.defaultContact.support.email
      }
      if (this.company?.clientAdvisor) {
        return this.company.clientAdvisor.email
      }
      return this.defaultContact.email
    },

    advisor () {
      return isAuthenticatedAndExpertUser(this.$auth) && this.company?.clientAdvisor
    },

    defaultContact () {
      return this.$features.feature(featureNames.SUPPORT_CONTACT).config
    },

    name () {
      return this.advisor?.name || this.defaultContact.name
    },

    photo () {
      return this.advisor?.photo || this.defaultContact.photo
    },

    whatsappLink () {
      if (this.allowedCompanies.includes(this.company?.id) && this.company?.clientAdvisor?.whatsappNumber) {
        return `https://wa.me/${this.company.clientAdvisor.whatsappNumber}`
      }
      return false
    },

    calendlyLink () {
      if (this.allowedCompanies.includes(this.company?.id) && this.company?.clientAdvisor?.calendlyUsername) {
        return `https://calendly.com/${this.company.clientAdvisor.calendlyUsername}/ubergabe-neue-bottimmo-app`
      }
      return false
    },
    isExpert () {
      return isAuthenticatedAndExpertUser(this.$auth)
    },
    isBasic () {
      return isAuthenticatedAndBasicUser(this.$auth)
    },
    isLight () {
      return isAuthenticatedAndLightUser(this.$auth)
    },
    hasDedicatedSupport () {
      return this.$features.feature(featureNames.APP_BRANDING).config?.hasDedicatedSupport
    }
  }
}
</script>
